import styles from './imdex.module.scss'
import Header from '../header'
import { Row } from 'antd'
function Main({ children, color }) {
	return (
		<Row className={styles.mian_content} style={{ backgroundColor: color }}>
			<Header></Header>
			{children}
		</Row>
	)
}
export default Main
