import styles from './imdex.module.scss'
import classNames from 'classnames'
function UButton({ children, className, onClick, ...reset }) {
	return (
		<div className={classNames(styles.main, className)} onClick={onClick} {...reset}>
			{children}
		</div>
	)
}
export default UButton
