import styles from './index.module.scss'
import UButton from '../../../components/uButton'
import img1 from './image1.png'
import img2 from './image2.png'
function MobleFooterbox({ onBntClick, onInvite }) {
	return (
		<div className={styles.mian_plans}>
			<div className={styles.box_item}>
				<img src={img1} />
				<div className={styles.content}>
					<header>👍 REFERRAL PROGRAM</header>
					<h2>Undress For Free</h2>
					<p>
						For each friend you invite you get <br /> free credits that can be used for <br /> Undress AI！
					</p>
					<div className={styles.text}>
						1 invitation: <b>6 credits</b>
					</div>
					<UButton className={styles.bnt} onClick={onInvite}>
						Invite friends
					</UButton>
				</div>
			</div>
			<div className={styles.box_item}>
				<img src={img2} />
				<div className={styles.content}>
					<header>😚 GET IN TOUCH</header>
					<h2>
						Connect
						<br />
						us in telegram
					</h2>
					<p>
						Write to us about any question or <br /> problem. We will try to help you.
						<br /> Feedback is very important to us
						<br /> in order to get better
					</p>
					<div></div>
					<UButton className={styles.bnt} onClick={onBntClick}>
						Support
					</UButton>
				</div>
			</div>
		</div>
	)
}
export default MobleFooterbox
