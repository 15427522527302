import React from 'react'
import ReactDOM from 'react-dom/client'
import { ConfigProvider } from 'antd'
import App from './App'
import reportWebVitals from './reportWebVitals'
import themeConfig from './config/theme.js'
import './main.css'
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<ConfigProvider theme={themeConfig}>
			<App />
		</ConfigProvider>
	</React.StrictMode>
)
reportWebVitals()
