
export function copyContent (content) {
  const copyDom = document.createElement('input')
  copyDom.style.position = 'absolute'
  copyDom.style.top = '0px'
  copyDom.style.right = '-9999px'
  document.body.appendChild(copyDom)
  copyDom.setAttribute('value', String(content))
  copyDom?.select() // 选择元素
  // 复制
  const successful = document.execCommand('copy')
  copyDom?.parentNode?.removeChild(copyDom)
  try {
    const msg = successful ? 'successful' : 'failed'
    console.log('Copy command was : ' + msg, successful)

  } catch (err) {
    console.log('Oops , unable to copy!')
  }
}

export const setStore = (key, data) => {
  window.localStorage.setItem(key, JSON.stringify({ value: data }))
}
export const getStore = (key) => {
  const data = window.localStorage.getItem(key)
  try {
    const _data = JSON.parse(data)
    return _data.value
  } catch (e) {
    console.error('localStorage  获取失败')
    return ''
  }
}
export const removeStore = (key) => {
  window.localStorage.removeItem(key)
}
export const getParams = (url) => {
  if (url) {
    try {
      const keyValueArr = url.split('?')[1].split('&')
      const paramObj = {}
      keyValueArr.forEach((item) => {
        const keyValue = item.split('=')
        paramObj[keyValue[0]] = keyValue[1]
      })
      return paramObj
    } catch (e) {
      return {}
    }
  } else return {}
}
export function isMobile () {
  const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  return flag
}
export const womanBaseTags = [
  {
    label:'Base',
    value:["woman"]
  },
  {
    label: 'Number of people',
    value: ['one']
  },
  {
    label: 'Clothing',
    value: ['nude']
  }
]
export const getRandonInt = (max) => {
  return Math.floor(Math.random()*max)
}
export const addGtagEvent = (key) => {
  const gtag = window.gtag
  gtag('event', key)
}
