import styles from './imdex.module.scss'
import classNames from 'classnames'
import { useState } from 'react'
import icon1Url from './images/icon1.png'
import icon2Url from './images/icon2.png'

function Collapse({ items = [] }) {
	const [active, setActive] = useState(0)
	const onHeader = (index) => {
		if (active !== index) setActive(index)
	}
	return (
		<div className={styles.main}>
			{items.map((v, index) => {
				return (
					<div className={styles.main_item} key={index}>
						<div onClick={() => onHeader(index)} className={styles.header}>
							<span>{v.title}</span>
							<img src={active === index ? icon2Url : icon1Url} />
						</div>
						<div className={classNames(styles.content, { [styles.active]: active === index })}>{v.value}</div>
					</div>
				)
			})}
		</div>
	)
}

export default Collapse
