import styles from './imdex.module.scss'
import Main from '../components/Main'
import UButton from '../components/uButton'
import classNames from 'classnames'
import img1url from './images/img1.png'
import card1Url from './images/card1.png'
import card2Url from './images/card2.png'
import card3Url from './images/cad3.png'
import img2url from './images/img2.png'
import img3url from './images/img3.png'
import img4url from './images/img4.png'
import img5url from './images/img5.png'
import tgUrl from './images/tgicon.png'
import emailUrl from './images/emial.png'
import { Col, Row } from 'antd'
import Collapse from '../components/Collapse'
import { CollapseItems } from './configs'
import JoinTheCommunity from '../components/JoinTheCommunity'
import { useState, useRef } from 'react'
import MessgaBox from '../components/MessgaBox'
import { copyContent } from '../utils/uitl'
import MobliePlans from './components/MobliePlans'
import MobleFooterbox from './components/MobleFooterbox'

function Home() {
	const onTg = () => {
		window.gtag('event', 'main_click_02')
		setShow(true)
	}
	const message = useRef()
	const onInvite = () => {
		copyContent(window.location.href)
		message.current.show()
	}
	const [show, setShow] = useState(false)
	return (
		<Main>
			{show ? <JoinTheCommunity onColse={setShow} /> : null}
			<MessgaBox ref={message} />
			<div className={styles.home_main}>
				<h2 className={styles._title}>Undress Online Deepnude AI</h2>
				<div className={styles.sub__title}>
					Undress AI photo generator for free deepnudes. Undress deepnude AI <br /> with the highest accuracy of deepfake nudes and bikini photos!
				</div>
				{buttonWarp({ onTg })}
				<div className={styles.account_title}>Already have account?</div>
				<div className={styles.login_text}>
					<a style={{ textDecoration: 'underline' }} onClick={onTg}>
						Log In
					</a>
				</div>
				<img className={styles.img1} src={img1url} />
				<div className={styles.home_card_warp}>
					{homeCard('100,000+', 'users every day', card1Url)}
					{homeCard('User-friendly', 'and intuitive interface', card2Url)}
					{homeCard('Trustworthy', 'the first deepnude app', card3Url)}
				</div>
				<div className={classNames(styles.card_box, styles.card_box1)}>
					<h2>How it work？</h2>
					<div className={styles.sub_title}>
						Our AI model has undergone extensive image training, ensuring that every <br /> image you process achieves the optimal and most realistic results.
					</div>
					<div className={styles.note_text}>
						* Secure anonymous registration.
						<br />
						* Upload photos of people standing at normal angles.
						<br />* Choose a style mode, and you can get started
					</div>
					{buttonWarp({ onTg, text: 'Try for free' })}
					<img className={styles.img2} src={img3url} />
				</div>
				<div className={classNames(styles.card_box, styles.card_box_works)}>
					<h2>How it works like?</h2>
					<div className={styles.sub_title}>
						When you upload an image, our application will promptly process it and <br />
						delive the highest quality result based on your subscription level
					</div>
					{buttonWarp({ onTg, text: 'Try for free' })}
					<img className={styles.img2} src={img2url} />
				</div>
				<Col span={0} lg={24}>
					<div className={classNames(styles.card_box, styles.card_box_plans)}>
						<h2>Our plans</h2>
						<div className={styles.plans_item_warp}>
							<Row justify={'center'} gutter={20} align={'bottom'}>
								<Col span={6}>
									<div className={styles.plans_item}>
										<h4>NO PLAN</h4>
										<p>4 credits</p>
										<h4>$0</h4>
										<UButton onClick={onTg} className={styles._bnt}>
											Try for free
										</UButton>
										<p>Medium quality</p>
									</div>
								</Col>
								<Col span={6}>
									<div className={styles.plans_item}>
										<div className={styles.popular}>Most popular</div>
										<h4>PREMIUM</h4>
										<p>600 credits</p>
										<h4>$47.99</h4>
										<UButton onClick={onTg} className={styles._bnt}>
											Try for free
										</UButton>
										<p>Excellent quality</p>
										<p>More Style</p>
										<p>Lastest AI Model</p>
										<p>No Queue</p>
									</div>
								</Col>
								<Col span={6}>
									<div className={styles.plans_item}>
										<h4>STANDARD</h4>
										<p>90 credits</p>
										<h4>$24.99</h4>
										<UButton onClick={onTg} className={styles._bnt}>
											Try it now
										</UButton>
										<p>High quality</p>
										<p>More Style</p>
										<p>Lastest AI Model</p>
										<p>No Queue</p>
									</div>
								</Col>
								<Col span={6}>
									<div className={styles.plans_item}>
										<h4>BASIC</h4>
										<p>15 credits</p>
										<h4>$11.99</h4>
										<UButton onClick={onTg} className={styles._bnt}>
											Try it now
										</UButton>
										<p>Medium quality</p>
										<p>More Style</p>
										<p>Lastest AI Model</p>
										<p>No Queue</p>
									</div>
								</Col>
							</Row>
						</div>
					</div>
				</Col>
				<Col span={24} lg={0}>
					<MobliePlans onBntClick={onTg} />
				</Col>
				<div className={classNames(styles.card_box, styles.card_box_confidential)}>
					<Col span={0} lg={24}>
						<h2>It’s completely safe</h2>
					</Col>
					<Col span={24} lg={0}>
						<h2>
							It’s completely <br />
							safe
						</h2>
					</Col>
					<div className={styles.sub_title}>
						We don’t save data. this is completely confidential and your actions are
						<br /> not published anywhere
					</div>
					{buttonWarp({ onTg, text: 'Try it Now' })}
				</div>
				<div className={classNames(styles.card_box, styles.card_box_faq)}>
					<div className={styles.card_box_faq_warp}>
						<h2 style={{ marginBottom: 20 }}>FAQ</h2>
						<Collapse items={CollapseItems} />
					</div>
				</div>
				<Col span={0} lg={24}>
					<div className={styles.footer_box}>
						<img src={img4url} />
						<div className={styles.footer_box_content}>
							<p>👍 REFERRAL PROGRAM</p>
							<h2>Undress For Free</h2>
							<p>
								For each friend you invite you get free credits that can
								<br />
								be used for Undress AI!
							</p>
							<div className={styles.txt}>1 invitation: 6 credits</div>
							<UButton onClick={onInvite} className={styles.footer_box_bnt}>
								Invite friends
							</UButton>
						</div>
					</div>
					<div className={styles.footer_box}>
						<img src={img5url} />
						<div className={styles.footer_box_content}>
							<p>👍 GET IN TOUCH</p>
							<h2>Connect us in telegram</h2>
							<p>
								Write to us about any question or problem. We will <br />
								try to help you. Feedback is very important to us in order to get better
							</p>
							<UButton onClick={onTg} className={styles.footer_box_bnt}>
								Support
							</UButton>
						</div>
					</div>
				</Col>
				<Col span={24} lg={0}>
					<MobleFooterbox onInvite={onInvite} onBntClick={onTg} />
				</Col>
				<footer>
					<div className={styles.links}>
						<a href={'/privacy'}>Privacy policy</a>
						<a href={'/privacy'}> Terms of service </a>
						<a href={'https://undress-ai.app/blog/'}>Blog</a>
					</div>
					<div className={styles.icons}>
						<a href='mailto:undress_contact@proton.me'>
							<img src={emailUrl} />
						</a>
						<img onClick={onTg} src={tgUrl} />
					</div>
					<div>We do not save any image data. The individual who produces the images holds sole responsibility.</div>
				</footer>
			</div>
		</Main>
	)
}
export default Home
function homeCard(title, subTitle, url) {
	return (
		<div className={styles.home_card}>
			<div className={styles.text}>
				<p>{title}</p>
				<p>{subTitle}</p>
			</div>
			<img src={url} />
		</div>
	)
}
function buttonWarp(params = {}) {
	const { onTg, text = 'Try it Now' } = params
	return (
		<div className={styles.buttonWarp}>
			<UButton className={styles.buttonWarp_bnt} onClick={onTg}>
				{text}
			</UButton>
		</div>
	)
}
