import styles from './imdex.module.scss'
import { Col, Row, Dropdown, Space } from 'antd'
import img1Url from './images/sanjiao.png'
import img2Url from './images/img_icon_1.png'
import { useState } from 'react'
import HeaderMobile from './headerMobile'

const gofaceswap = () => {
	window.gofaceswap()
}
const gojudy = () => {
	window.goJudy()
}
const goPornpen = () => {
	window.goPornpen()
}
const items = [
	{
		key: '1',
		label: 'undress.vip '
	},
	{
		key: '2',
		label: 'undress-ai.app'
	}
]
const handleMenuClick = (e) => {
	switch (e.key) {
		case '1':
			window.open('https://undress.vip', '_blank')
			break
		default:
			window.open('https://undress-ai.app', '_blank')
	}
}
const links = window.linksConfig || []
function Header() {
	const [showHeader, setShowHeader] = useState(false)
	return (
		<div className={styles.header_main}>
			<div className={styles.logo}>
				<img src={'/logo.png'} alt='' />
				<p>Undress.VIP</p>
			</div>
			<Row className={styles.row}>
				<Col className={styles.row_pc} span={0} lg={20}>
					<div>
						{links.map((v, index) => {
							return (
								<a key={index} target={'_blank'} href={v.url}>
									{v.text}
								</a>
							)
						})}
					</div>
					{/*<ul>*/}
					{/*	<li>*/}
					{/*		<Dropdown trigger={['click']} menu={{ items, onClick: handleMenuClick }} overlayClassName={styles.Dropdown_header}>*/}
					{/*			<a onClick={(e) => e.preventDefault()}>*/}
					{/*				<Space>*/}
					{/*					AI Undress <img style={{ width: 20, height: 'auto' }} src={img1Url} alt='' />*/}
					{/*				</Space>*/}
					{/*			</a>*/}
					{/*		</Dropdown>*/}
					{/*	</li>*/}
					{/*	<li onClick={gofaceswap}>AI FaceSwap</li>*/}
					{/*	<li onClick={gojudy}>AI Girlfriends</li>*/}
					{/*	<li onClick={goPornpen}>Pornoen</li>*/}
					{/*	<li>*/}
					{/*		<a href='https://undress-ai.app/blog/'>Blog</a>{' '}*/}
					{/*	</li>*/}
					{/*</ul>*/}
				</Col>
				<Col span={1} lg={0} style={{ paddingRight: 30 }}>
					<img onClick={() => setShowHeader(true)} src={img2Url} style={{ width: 26, height: 'auto' }} alt='' />
					{showHeader ? <HeaderMobile links={links} onChange={setShowHeader} /> : null}
				</Col>
			</Row>
		</div>
	)
}

export default Header
