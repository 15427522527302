//  eslint-disable-next-line
import { theme } from 'antd'

export default {
	token: {
		// colorPrimary: '#489D72',
		borderRadius: 10
	},
	algorithm: theme.defaultAlgorithm,
	components: {
		Card: {
			borderRadius: 0
		},
		Collapse: {
			headerBg: '#fff'
		},
		Modal: {}
	}
}
