import Home from './pages/home'
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom'
import Privacy from './pages/Privacy/Privacy'
import Agreement from './pages/Privacy/Agreement'
import './App.css'
const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />
	},
	{
		path: '/privacy',
		element: <Privacy />
	},
	{
		path: '/agreement',
		element: <Agreement />
	},
	{
		path: '*',
		element: <Navigate to={'/'} replace />
	}
])
function App() {
	return (
		<div className={'app_main'}>
			<RouterProvider router={router}></RouterProvider>
		</div>
	)
}

export default App
