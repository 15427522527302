import { message } from 'antd'
import { forwardRef, useImperativeHandle } from 'react'

export default forwardRef((props, ref) => {
	const [messageApi, contextHolder] = message.useMessage()
	useImperativeHandle(ref, () => {
		return {
			show: (params = {}) => {
				const { content = 'Coped successful!', type = 'success', duration = 3 } = params
				messageApi.success({
					type,
					key: 'updatable',
					content,
					duration,
					className: 'custom-class-message'
				})
			}
		}
	})
	return <>{contextHolder}</>
})
