import { Row, Col } from 'antd'
// import logo from '../../images/logo.jpg'
import './index.scss'
function Privacy() {
	return (
		<Row justify={'center'}>
			<Col span={23} lg={18}>
				<div className='Policy'>
					{/*<div className="title">*/}
					{/*    <a style={{color: "white"}} href='/'> <h2>undress.vip</h2></a>*/}
					{/*    <a href='/'><img src={logo} /></a>*/}
					{/*</div>*/}
					<h2 style={{ margin: '40px 0' }}>undress.vip Terms of Services and Privacy Policy</h2>
					<p className='April'>April 18, 2023</p>
					<strong>By using our bot, you are agreeing to be bound by these Terms of Use and our Privacy Policy.</strong> Your use of the bot for any purpose other than accessing and accepting these Terms of Use or for any other purpose not expressly authorized by us is strictly prohibited. If you do not agree to these terms, you must immediately cease using our bot.
					<h4>User Information</h4>
					undress.vip modifies photos that users upload to our service. In order to provide this service, we collect and process the photos that users upload to our service. We do not share user photos with any third parties except for Telegram, where the bot is located. The photos that users send to undress.vip are only used for the purpose of modifying the photo and sending it back to the user. We do not use the photos for any other purpose, and we take appropriate measures to protect user
					privacy and data security. By using our service, you agree to our collection and processing of your photos and usage data as described in this policy.
					<h4>Information Protection</h4>
					At undress.vip, we are committed to protecting the personal and private information of our users. We understand the sensitive nature of the photos that our users send us and have implemented a number of measures to ensure the security and privacy of this data.
					<br />
					To protect user information, we use the same level of protection as Telegram does in our Telegram bot. This means that all data transmission between undress.vip and Telegram is encrypted, and all photos are stored in Telegram's cloud servers, which are secure and protected.
					<br /> When we modify user photos, we use secure servers to ensure that the data is protected. We do not store user data beyond what is necessary to complete the photo modification and delivery process. As soon as the photo is delivered to the user, it is deleted from our servers. We do not share user photos with any third parties. We take appropriate technical and organizational measures to protect user data against unauthorized access, disclosure, alteration, and destruction. These
					measures include regular security assessments, monitoring, and testing, as well as training for our staff on data security and privacy best practices.
					<br />
					By using our service, you agree to our collection, processing, and protection of your information as described in this policy. We are committed to complying with applicable data protection laws and regulations and continuously improving our data security practices.
					<h4>Intellectual Property</h4>
					By submitting any file or content, or making any comment on our bot, you represent and warrant that such action does not violate or infringe upon the intellectual property rights or any other rights of any third party. You further represent and warrant that you have created the file or content you are uploading, or you have the necessary intellectual property rights to submit the material in accordance with these terms. With respect to any file or content you upload to both the public
					and private parts of our bot, you grant us a non-exclusive, royalty-free, perpetual, and irrevocable worldwide license, including sublicensing and attribution rights, to download, process, and perform automated modifications on the file or content. To the extent that you exclude any file or content from the private parts of our bot, the license you grant us in accordance with the previous sentence will automatically terminate, but will not be revoked in relation to any file or content
					that we have already copied and sublicensed or designated for sublicensing. Additionally, any file or content that you post to a public part of our bot can be used by the public in accordance with the license terms specified above, even if you subsequently delete such file or content. Please note that we reserve the right to remove any content or file that you upload to our bot at any time should we discover violation of our Terms of Service, and without prior notice to you.
					<h4>Children's Online Privacy Protection Act</h4>
					Our service is only intended for use by individuals who are 18 years of age or older. We do not knowingly collect or solicit any personally identifiable information from children under the age of 18. If we become aware that we have inadvertently collected personal data from a child under the age of 18, or if we receive a report of any content related to a child under the age of 18 on our service, we will take immediate steps to delete such information as quickly as possible. If you
					become aware of anyone under the age of 18 using our service. We are committed to complying with the Children's Online Privacy Protection Act (COPPA) and any other applicable laws and regulations regarding the protection of children's privacy online.
					<h4>Prohibited Actions</h4>
					You agree that you will not engage in any activities that violate our Terms of Use. Specifically, you agree that you will not upload or post any content that infringes upon the intellectual property rights of others, including but not limited to copyrighted material. You also agree that you will not upload or post any content that contains hate speech or material that is threatening, offensive, defamatory, or that encourages violence or crime. You further agree that you will not upload
					or post any illegal content, such as child pornography or non-consensual pornography (revenge porn). You agree that you will not impersonate another person or entity or falsely represent yourself in any way. Additionally, you agree that you will not use our service to host libraries of images to which you link from elsewhere, or to host content for your website, advertising, avatars, or any other content that is not directly related to the functionality of our service. You understand
					that any such conduct is strictly prohibited and may result in legal consequences. You further acknowledge that you are solely responsible for your actions on our service, and that we will not be held legally responsible for any such actions, including but not limited to any infringement of intellectual property rights or any harm caused by the use of our service. Any violation of these Terms of Use may result in the termination of your access to the service, as well as other legal
					remedies as may be available to us. If you engage in any of the prohibited activities mentioned above, or if you engage in any illegal activity using our service, we reserve the right to take all necessary actions to protect our platform and other users. This includes, but is not limited to, terminating your access to our service, and deleting all of your images. In addition, we reserve the right to prevent you from accessing any images hosted on our bot. Please note that any violation
					of these Terms of Use may also result in other legal remedies as may be available to us under applicable law.
					<h4>Disclaimer of Warranties Limitation of Liability and Indemnification</h4>
					Once our bot has processed an image, we consider the service to have been rendered. Therefore, if you have received an image from our bot, we will not be able to provide you with a refund. If there is a technical issue or error on our part that prevents our bot from processing an image, please contact us immediately and we will work to resolve the issue as quickly as possible. Our services are provided "as is" and "with all faults". Your use of our service is entirely at your own risk.
					We do not guarantee the availability or reliability of our service at any given time, and we do not guarantee the integrity or continuous availability of files on our servers. Whether we make backups, and if so, whether restoration of those backups will be available to you, is at our discretion. To the fullest extent permitted by applicable law, we disclaims all warranties, express or implied, including, without limitation, the implied warranties of fitness for a particular purpose and
					merchantability. Regardless of anything established in these Terms, and whether or not undress.vip takes measures to remove inappropriate or harmful content from its site, undress.vip has no obligation to monitor any content. undress.vip does not assume responsibility for the accuracy, fitness, or quality of any content that appears on undress.vip that is not produced by undress.vip, including but not limited to user-generated content. Your only remedy for the loss of any services or
					data that you may have stored on our service is to stop using our service. To the extent permitted by applicable law, undress.vip shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use or inability to use our service, even if we have been advised of the possibility of such damages. No cause of action arising out of your use of our service may be brought more than one year after the occurrence of the
					cause of action. You agree to indemnify, defend, and hold harmless undress.vip, its affiliates, and their respective officers, directors, employees, and agents from any and all losses, liabilities, claims, damages, and expenses, including reasonable attorneys' fees, arising out of or related to your breach of these Terms or any damage caused to any third party as a result of your upload of files, comments, or any other content to our servers.
					<h4>Claimed Copyright Infringement Notices</h4>
					If you believe that any material on our bot infringes upon your copyright or other intellectual property rights, please notify our designated Digital Millennium Copyright Act (DMCA) agent by providing the following information: Identification of the copyrighted work or works that you claim have been infringed, including your copyright registration number if available. If the work has not yet been registered, please include a copy of the form to register the work you have filed with the
					Copyright Office. Please note that a copyright infringement claim based on a work in the U.S. can only be filed if the work has been registered (www.copyright.gov/eco/).
					<br />. Identification of the material on our bot that is found to be infringing, including the URL or other information that allows us to locate the material.
					<br />. A statement that you have a good faith belief that the use of the material in the manner complained of is not authorized by you, the copyright owner, or its agent, or by law.
					<br />. A statement that the information in your notice is accurate and, under penalty of perjury, that you are the owner of the exclusive copyright right that is allegedly being infringed or that you are authorized to act on behalf of the owner.
					<br />. Your physical or electronic signature, or the signature of someone authorized to act on your behalf.
					<br />. Instructions on how we can contact you, including your email address, physical address, and phone number if available. Please send your notice to our designated DMCA agent at the following address: [Insert name and contact information of DMCA agent] Please note that any misrepresentation of material or activity that is infringing or misidentification of the material or activity that is infringing may result in liability for damages.
					<h4>Policy Updates:</h4>
					We reserve the right to update or modify this policy at any time and for any reason, including operational, legal, or regulatory purposes. Any changes to this policy will be posted on this page with the revised date. Your continued use of our service after any such changes to this policy constitutes your acceptance of the new terms. We encourage you to periodically review this policy to stay informed of any updates or modifications.
					<br />
					Last modified: April 18, 2023.
				</div>
			</Col>
		</Row>
	)
}
export default Privacy
