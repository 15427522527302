import styles from './imdex.module.scss'
import { CloseOutlined, RightOutlined } from '@ant-design/icons'
const gofaceswap = () => {
	window.gofaceswap()
}
const gojudy = () => {
	window.goJudy()
}
const goPornpen = () => {
	window.goPornpen()
}
function HeaderMobile({ onChange, links = [] }) {
	return (
		<div className={styles.main}>
			<header>
				<div>
					<img src={'/logo.png'} alt={''} /> Undress.vip
				</div>
				<CloseOutlined onClick={() => onChange(false)} color='#fff' style={{ fontSize: 24 }} />
			</header>
			<ul>
				{links.map((v, index) => {
					return (
						<li key={index}>
							<a href={v.url}>
								<span>{v.text}</span>
								<RightOutlined />
							</a>
						</li>
					)
				})}

				{/*<li>*/}
				{/*	<a href={'https://undress-ai.app'}>*/}
				{/*		<span>Undress-ai.vip</span>*/}
				{/*		<RightOutlined />*/}
				{/*	</a>*/}
				{/*</li>*/}
				{/*<li onClick={gofaceswap}>*/}
				{/*	<span>AI Faceswap</span>*/}
				{/*	<RightOutlined />*/}
				{/*</li>*/}
				{/*<li onClick={gojudy}>*/}
				{/*	<span>AI Girfriend</span>*/}
				{/*	<RightOutlined />*/}
				{/*</li>*/}
				{/*<li onClick={goPornpen}>*/}
				{/*	<span>Prompen</span>*/}
				{/*	<RightOutlined />*/}
				{/*</li>*/}
				{/*<li>*/}
				{/*	<a href={'https://undress-ai.app/blog/'}>*/}
				{/*		<span>Blog</span>*/}
				{/*		<RightOutlined />*/}
				{/*	</a>*/}
				{/*</li>*/}
			</ul>
		</div>
	)
}
export default HeaderMobile
