import { Row, Col } from 'antd'
// import logo from '../../images/logo.jpg'
import './index.scss'
function Agreement() {
	return (
		<Row justify={'center'}>
			<Col span={23} lg={18}>
				<div className='Policy'>
					{/*<div className="title">*/}
					{/*    <a style={{color: "white"}} href='/'> <h2>undress.vip</h2></a>*/}
					{/*    <a href='/'><img src={logo} /></a>*/}
					{/*</div>*/}
					<h2 style={{ margin: '40px 0' }}>User Agreement</h2>
					<p class='April'>June 05, 2023</p>
					Welcome to undress.vip , an image processing tool. By using our tool, you agree to the following terms and conditions:
					<br />
					1. Tool disclaimer undress.vip is provided "as is", and we do not guarantee the accuracy, completeness, or usefulness of any processed image generated by our tool. This is a service for upgrading pictures. Our platform provides tools and features that allow users to enhance and modify their photos. Users should use their own discretion when using the platform, and we shall not be liable for any damages or losses resulting from the use of this service.
					<br />
					2. User responsibility. For uploaded content our platform does not assume any responsibility for photos or images uploaded by users. It is the sole responsibility of the user to ensure that they have obtained all necessary permissions and have the rights to use and upload the photos. Any claims or legal actions related to the intellectual property rights of the uploaded pictures will be the responsibility of the user who uploaded them.
					<br />
					3. Age restrictions and eligibility persons accessing and utilizing our tool must be at least 18 years of age or older. We expressly prohibit access to and usage of our tool by individuals under the age of 18 ("Minors").
					<br />
					4. User obligations for photos of minors. If any user uploads a photo or image of a minor, or if we have reason to believe that the person in the photo is a minor, we reserve the right to report such activity to the appropriate authorities. It is the responsibility of the user to ensure that they have the legal right to upload the photo and that all applicable laws and regulations have been followed. Any violation of this policy may result in the termination of the user's account and
					legal action may be taken against them."
					<br /> 5. Content risks. Some images processed using undress.vip may be offensive, inappropriate, or otherwise objectionable. By using our tool, you acknowledge and accept the risks associated with processing such content and agree that we are not liable for any harm or liability arising from your use of our tool or the content processed by our tool.
					<br />
					6. Prohibited content. You agree not to use undress.vip to process images that are defamatory, discriminatory, harassing, threatening, or otherwise unlawful.
					<br /> 7. Payment and billing. Payment for using undress.vip is due in advance and non-refundable. You may choose from our available payment methods. We reserve the right to change our fees and payment methods at any time with prior notice.
					<br />
					8. Modification, suspension, and termination. We reserve the right to modify, suspend, or terminate undress.vip at any time, with or without notice, for any reason, including but not limited to non-payment or violation of our terms and conditions.
					<br />
					9. Distribution liability. You are solely responsible for the distribution of any images that you processed using undress.vip, and we are not responsible for any harm or liability that may arise from the distribution of such images.
					<br />
					10. Indemnification. You agree to indemnify and hold harmless undress.vip and its owners, officers, employees, and agents from and against any claims, liabilities, damages, and expenses (including reasonable attorneys' fees) arising from your use of our tool or any violation of these terms and conditions.
					<br /> 11. Refund policy. All transactions and activities conducted through the tool on the site are final and non-refundable. By using undress.vip, you acknowledge that you have read, understood, and agreed to these terms and conditions, including the payment and billing terms.
				</div>
			</Col>
		</Row>
	)
}
export default Agreement
