// import { useSelector } from 'react-redux'
import classNames from 'classnames'
import styles from './index.module.scss'
import url1 from './images/1.png'
import url2 from './images/2.png'
import url3 from './images/3.png'
import url4 from './images/4.png'
import url5 from './images/5.png'
import url6 from './images/6.png'
import closeUrl from './images/c.png'
import tboxUrl from './images/t.png'
import UButton from '../uButton'
// import { useDispatch } from 'react-redux'
const icons = [url1, url2, url3, url4, url5, url6]
const texts = ['Perfect payment', 'Customer service on time', 'Pornpen - create porn image with prompt', 'Image Edit - more style experience', 'Face Swap - Just upload one photo, hit Generate, and watch the AI seamlessly swaps faces in seconds.', 'Judy Soulmate - Uncensored AI Girlfriend — Cutting edge AI Companion - Chat, exchange photos, voice and phone call! Voice...']

function JoinTheCommunity({ show, onColse }) {
	// const dispatch = useDispatch()
	const _onColse = () => {
		onColse(false)
	}
	const onBtn = (key) => {
		switch (key) {
			case 1:
				return window.goUndress()
			case 2:
				return window.gofaceswap()
			case 3:
				return window.goJudy()
			case 4:
				return window.goPornpen()
		}
	}
	return (
		<>
			<div className={classNames(styles.main_JoinTheCommunity, { [styles.main_JoinTheCommunity_box]: show })}>
				<div className={classNames(styles.container, { [styles.container_show]: show })}>
					{!show ? <img onClick={_onColse} className={styles.closeUrl} src={closeUrl} /> : null}
					<h3 className={classNames({ [styles.title]: show })}>Join the community</h3>
					{icons.map((v, index) => {
						return (
							<div className={styles.text} key={index}>
								<img src={v} /> {texts[index]}
							</div>
						)
					})}
					<div style={{ marginTop: 30 }}>
						<UButton onClick={() => onBtn(1)} className={styles.bnt}>
							<div>
								<img src={tboxUrl} /> @Undress Bot
							</div>
							<span className={styles.hot}>hot</span>
						</UButton>
						<UButton onClick={() => onBtn(2)} className={styles.bnt}>
							<div>
								<img src={tboxUrl} /> @FaceSwap Bot
							</div>
							<span className={styles.new}>new</span>
						</UButton>
						<UButton onClick={() => onBtn(3)} className={styles.bnt}>
							<div>
								<img src={tboxUrl} /> @Judy Bot
							</div>
							<span className={styles.hot}>hot</span>
						</UButton>
						<UButton onClick={() => onBtn(4)} className={styles.bnt}>
							<div>
								<img src={tboxUrl} />
								@Pornpen Bot
							</div>
						</UButton>
					</div>
				</div>
			</div>
		</>
	)
}

export default JoinTheCommunity
