import styles from './index.module.scss'
import UButton from '../../../components/uButton'
import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'
import img5 from './images/5.png'
import img6 from './images/6.png'
import img7 from './images/7.png'
import img8 from './images/8.png'
import img9 from './images/9.png'
function MobliePlans({ onBntClick }) {
	return (
		<div className={styles.mian_plans}>
			<h2>Our plans</h2>
			<div className={styles.card_box1}>
				<div>
					<header>
						<span>NO PLAN</span>
						<p>4 credits</p>
					</header>
					<div className={styles.line}>
						<span>
							<img src={img1} />
						</span>
						Medium quality
					</div>
					<div className={styles.line}>
						<span>
							<img src={img2} />
						</span>
						One Style
					</div>
				</div>
				<UButton onClick={onBntClick} className={styles.bnt}>
					Try for free
				</UButton>
			</div>
			<div className={styles.card_box2}>
				<div>
					<header>
						<span>
							PREMIUM
							<i>
								<img src={img9} />
								<b>hot</b>
							</i>
						</span>
						<p>600 credits</p>
					</header>
					<div className={styles.line}>
						<span>
							<img src={img3} />
						</span>
						Excellent quality
					</div>
					<div className={styles.line}>
						<span>
							<img src={img4} />
						</span>
						More Style
					</div>
					<div className={styles.line}>
						<span>
							<img src={img5} />
						</span>
						Lastest AI Model
					</div>
					<div className={styles.line}>
						<span>
							<img src={img6} />
						</span>
						No Queue
					</div>
				</div>
				<UButton onClick={onBntClick} className={styles.bnt}>
					$47.99/mo
				</UButton>
			</div>
			<div className={styles.card_box1}>
				<div>
					<header>
						<span>STANDARD</span>
						<p>90 credits</p>
					</header>
					<div className={styles.line}>
						<span>
							<img src={img7} />
						</span>
						High quality
					</div>
					<div className={styles.line}>
						<span>
							<img src={img4} />
						</span>
						More Style
					</div>
					<div className={styles.line}>
						<span>
							<img src={img5} />
						</span>
						Lastest AI Model
					</div>
					<div className={styles.line}>
						<span>
							<img src={img6} />
						</span>
						No Queue
					</div>
				</div>
				<UButton onClick={onBntClick} className={styles.bnt}>
					$24.99/mo
				</UButton>
			</div>
			<div className={styles.card_box1}>
				<div>
					<header>
						<span>BASIC</span>
						<p>15 credits</p>
					</header>
					<div className={styles.line}>
						<span>
							<img src={img8} />
						</span>
						Medium quality
					</div>
					<div className={styles.line}>
						<span>
							<img src={img4} />
						</span>
						More Style
					</div>
					<div className={styles.line}>
						<span>
							<img src={img5} />
						</span>
						Lastest AI Model
					</div>
					<div className={styles.line}>
						<span>
							<img src={img6} />
						</span>
						No Queue
					</div>
				</div>
				<UButton onClick={onBntClick} className={styles.bnt}>
					$11.99/mo
				</UButton>
			</div>
		</div>
	)
}
export default MobliePlans
